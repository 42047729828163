import type { VariantProps } from "cva";
import { cva } from "cva";
import type { HTMLAttributes } from "react";
import { createElement } from "react";
import { cn } from "../../utils";

const headingStyles = cva(
  "tw-font-medium tw-text-zinc-900 dark:tw-text-white",
  {
    variants: {
      size: {
        xs: "tw-text-xs",
        sm: "tw-text-sm",
        md: "tw-text-md",
        lg: "tw-text-lg",
        xl: "tw-text-xl",
        "2xl": "tw-text-2xl",
        "3xl": "tw-text-3xl",
      },
    },
  },
);

export interface HeadingProps
  extends HTMLAttributes<HTMLHeadingElement>,
    VariantProps<typeof headingStyles> {
  as: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
}

export function Heading({
  as,
  size = "lg",
  className,
  ...other
}: HeadingProps) {
  return createElement(as, {
    className: cn(headingStyles({ size }), className),
    ...other,
  });
}
